<template>
	<!-- <el-dialog append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" top="0"> -->
	<el-drawer :wrapperClosable="false" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1000px">
		<template slot="title">
			<div>
				<span>{{$t('i18nn_0950e42c6423c2a7')}}</span>-<span>{{$t('i18nn_b1d3482f4406f513')}}</span>
				<span v-if="inWhCode">【<span>{{$t('i18nn_35ec768075eb8f4e')}}</span>：{{inWhCode}}】</span>
				<span v-if="inWhRecord">【<span>{{$t('i18nn_e6bdd31025bb4f9f')}}</span>ID：{{inWhRecord}}】</span>
			</div>
		</template>
		<div class="mainTem" v-loading="loading_load">


			<!-- <div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span>转运库存-出库明细</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div> -->

			<!-- <div class="filterCon" style="">
			<div class="filterBarList">
					
			</div>
			<div class="filterAction">
				<ul class="actionConList">
					
				</ul>
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div> -->

			<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
				<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
					:max-height="$store.state.frameConHeightWh">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
						align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
						<template slot-scope="scope">
							<el-tag type="" v-if="'0' == scope.row.status">
								{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="success" v-else-if="'10' == scope.row.status">
								{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="warning" v-else-if="'60' == scope.row.status">
								{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="danger" v-else-if="'70' == scope.row.status">
								{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="warning" v-else-if="'80' == scope.row.status">
								{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="info" v-else-if="'99' == scope.row.status">
								{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						</template>
					</el-table-column>


					<el-table-column prop="trStockSourName" :label="$t('i18nn_9d3249981866af60')" width="">
					</el-table-column>

					<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')" width="">
					</el-table-column>

					<el-table-column prop="outWhCode" :label="$t('i18nn_7f6c721b57ed0e7b')" width="">
					</el-table-column>

					<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
					</el-table-column>



					<!-- <el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
				</el-table-column> -->

					<el-table-column prop="trCtnCount" :label="$t('i18nn_bdc361ba04506136')" width="">
					</el-table-column>

					<el-table-column prop="trLabel" :label="$t('i18nn_08e5062e3affb676')" width="">
					</el-table-column>
					<el-table-column prop="trPallte" :label="$t('i18nn_18c1c1a3ea76e9d3')" width="">
					</el-table-column>
					<el-table-column prop="trPallteLabel" :label="$t('i18nn_f88b93f4fd02974c')" width="">
					</el-table-column>
					<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')" width="">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
					<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							<el-button @click="showDetList($event, scope.row)" type="warning" size="mini" icon="el-icon-view">{{ $t('Storage.skuInfo.SKU_information') }}</el-button>
						</div>
						<div style="margin-top: 5px;">
							<el-button @click="showSkuLocation($event, scope.row)" type="primary" size="mini" icon="el-icon-search">{{ $t('i18nn_a3b1e5efc70b46eb') }}</el-button>
						</div>
						
					</template>
				</el-table-column> -->
				</el-table>

				<div class="tableCon_pagination">
					<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>

			<!--查看明细-->
			<!-- <el-dialog :title="$t('Storage.skuInfo.SKU_detail')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="10px">
			<div style="">
				<el-table :data="DetData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column>

					<el-table-column prop="goodsSku" label="SKU"></el-table-column>

					<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.Chinese_title')"></el-table-column>

					<el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')"></el-table-column>

					<el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
						<template slot-scope="scope">
							<div>
								<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
									<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
								</el-image>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
					<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

					<el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
					<el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
					<el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
					<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

			<!--查看库位明细-->
			<!-- <el-dialog :title="'SKU库位明细'" append-to-body :close-on-click-modal="false" :visible.sync="dialogSkuPlace" width="1000px" top="10px">
			<div style="" v-loading="loading_det">
				<el-table :data="SkuPlaceData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

					<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
					<el-table-column prop="place" :label="$t('i18nn_3a6eb04ef8f3327b')"></el-table-column>

					<el-table-column prop="stock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSkuPlace = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

			<!--查看图片-->
			<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

			<!-- excel 导出排序 -->
			<!-- <whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhTransferStockPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom> -->
		</div>
		<!-- <div slot="footer" class="dialog-footer">
	    <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
	  </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	// import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	export default {
		props: {
			openTime: {
				// default: function() {
				//   return '';
		 	// },
				// type: String
			},
			inWhRecord: {
				default: function() {
					return "";
				},
				type: String
			},
			inWhCode: {
				default: function() {
					return "";
				},
				type: String
			},
			row: {
				default: function() {
					return {};
				},
				type: Object
			},
		},
		components: {
			// SelAgentUser,
			// whExcelCustom,
			// whNoSelect
		},
		data() {
			return {
				//excel导出
				// excelOpenTime: '',
				// excelHead: [],
				// expExcelData: [],
				// excelName: '',
				// excelOption: {},

				dialogFile: false,

				loading: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_weight_unit: [],
					wh_vol_unit: [],
					wh_goods_fee_type: [],
					// wh_no: []
					// statusList: [
					//   {
					//     value: '',
					//     label: this.$t('i18nn_16853bda54120bf1')
					//   },
					//   {
					//     value: '1',
					//     label: this.$t('i18nn_fdd8426d7b8869c5')
					//   },
					//   {
					//     value: '2',
					//     label: this.$t('i18nn_619d7e563f879811')
					//   },
					//   {
					//     value: '3',
					//     label: this.$t('i18nn_1dfb3a065d3ffe39')
					//   }
					// ]
				},
				// inWhRecord:"",
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					agentUser: '',
					whNo: '',
					// goodsSku: '',
					// // hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					keyword: ''
					// declareNameCh: '',
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {

				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				console.log("this.inWhRecord", this.inWhRecord);
				if (this.inWhRecord || this.inWhCode) {
					// this.inWhRecord = this.row.id;
					this.getPageData();
				}
				// this.getDicData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			// showDetList($event, row) {
			// 	$event.stopPropagation();
			// 	this.dialogShowDet = true;
			// 	this.DetData = [row.mySku];
			// },
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			//库位查询
			// showSkuLocation($event, row) {
			// 	$event.stopPropagation();
			// 	this.dialogSkuPlace = true;
			// 	this.SkuPlaceData = [];
			// 	this.getSkuLocationData(row.goodsSku, row.whNo, this.filterData.agentUser);
			// 	// this.dialogShowDet = true;
			// 	// this.DetData = [row.mySku];
			// },

			//导出 excel
			// exportExcel() {
			// 	let columns = [
			// 		{
			// 			title: this.$t('i18nn_29991afa9781d554'),
			// 			key: 'cusName'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.SKU_title'),
			// 			key: 'goodsName',
			// 			types: 'custom',
			// 			formatter: function(row) {
			// 				return row.mySku.goodsName + row.mySku.goodsNameEn;
			// 			}
			// 		},
			// 		{
			// 			title: 'SKU',
			// 			key: 'goodsSku'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.warehouse'),
			// 			key: 'whNo'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.on_hand_inventory'),
			// 			key: 'totalStock'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.Current_locked_inventory'),
			// 			key: 'lockStock'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.Current_available_stock'),
			// 			key: 'currentStock'
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.unit'),
			// 			key: 'sizeUnitName',
			// 			types: 'custom',
			// 			formatter: function(row) {
			// 				return row.mySku.sizeUnitName;
			// 			}
			// 		},
			// 		{
			// 			title: this.$t('Storage.skuInfo.unit_price'),
			// 			key: 'declarePrice',
			// 			types: 'custom',
			// 			formatter: function(row) {
			// 				return row.mySku.declarePrice;
			// 			}
			// 		},
			// 		{
			// 			title: 'SKU ' + this.$t('Storage.skuInfo.length') + '*' + this.$t('Storage.skuInfo.width') + '*' + this.$t('Storage.skuInfo.height'),
			// 			// key: '{{scope.row.mySku.goodsLength}}*{{scope.row.mySku.goodsWidth}}*{{scope.row.mySku.goodsHeight}}'
			// 			key: 'lwh',
			// 			types: 'custom',
			// 			formatter: function(row) {
			// 				return row.mySku.goodsLength + '*' + row.mySku.goodsWidth + '*' + row.mySku.goodsHeight;
			// 			}
			// 		},
			// 		{
			// 			title: 'SKU ' + this.$t('Storage.skuInfo.weight'),
			// 			// key: 'mySku.goodsWeight',
			// 			key: 'goodsWeight',
			// 			types: 'custom',
			// 			formatter: function(row) {
			// 				return row.mySku.goodsWeight;
			// 			}
			// 		},

			// 		{
			// 			title: this.$t('Storage.tableColumn.remark'),
			// 			key: 'remark'
			// 		}
			// 	];
			// 	let Data = this.tableData;

			// 	this.expExcelData = Data;
			// 	this.excelHead = columns;
			// 	this.excelName = 'WSkuInventories';
			// 	this.excelOption = {height: 20};
			// 	this.excelOpenTime = new Date().getTime();

			// 	// excelUtilsNew.exportExcel(columns, Data, 'WSkuInventories', { height: 20 });
			// },


			// hyUpLoadImg1: function(childValue) {
			// 	// childValue就是子组件传过来的值
			// 	this.form.goodsImg = childValue;
			// },

			// //查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },

			//分页的筛选项数据
			pageFilterData() {
				return {
					inWhRecord: this.inWhRecord,
					inWhCode:this.inWhCode?this.inWhCode:null,
					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// // status: this.filterData.status ? this.filterData.status : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// // goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// // // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// // goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					// keyword: this.filterData.keyword ? this.filterData.keyword : null
					// // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},

			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferStockOutPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//库位查询
			// getSkuLocationData(sku, whNo, agentUser) {
			// 	// let _this = this;
			// 	this.loading_det = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhMyGoodsSkuPlace, {
			// 			goodsSku: sku,
			// 			whNo: whNo,
			// 			proxyUserId: agentUser ? agentUser : null
			// 		})
			// 		.then(({ data }) => {
			// 			this.loading_det = false;
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			if (data.data) {
			// 				this.SkuPlaceData = data.data.skuStockInfo;
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading_det = false;
			// 		});
			// },
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
